import barba from '@barba/core';
import { gsap } from "gsap/all";

import Scrolling from "./scrolling";
import Scrollup from "./scrollup";
import NavMobile from "./nav-mobile";
import FooterButton from "./footer-button";
import Title from "./title";
import Projects from "./projects";
import ServicesMenu from "./services-menu";
import Video from "./video";
import StickyHeadline from "./sticky-headline";
import Cursor from "./cursor";
import Slider from "./slider";

export default class BarbaJs {

  constructor() {
    // const navigationLinks = document.querySelectorAll('.navigation--header-link, .navigation--footer-link')
    const html = document.querySelector('html');
    const body = document.querySelector('body');
    const cursor = document.querySelector('#cursor');
    const languageSwitcher = document.querySelector('.language-switcher');
    let scrollX = 0;
    let scrollY = 0;

    // const navigationWrapper = document.querySelector('.header--navigation');
    // const navigation = document.querySelector('.header--navigation');
    // const navigationLinks = document.querySelectorAll('.navigation--header-link');


    barba.init({
      timeout: 6000,

      // debug: true,
      // cacheIgnore: true,
      // prefetchIgnore: true,

      // requestError: (trigger, action, url, response) => {
      //   console.log({
      //     trigger: trigger,
      //     action: action,
      //     url: url,
      //     response: response,
      //   })
      // },

      prevent: ({ el }) => el.classList && (el.classList.contains('prevent-barba') || el.classList.contains('active')),

      transitions: [
        {
          name: 'default-transition',

          once(data) {
            setTimeout(() => {
              new Scrolling();
              new Scrollup();
              new NavMobile();
              new FooterButton();
              new Title();
              new Projects();
              new ServicesMenu();
              new StickyHeadline();
              new Video('.js--video');
              new Cursor();
              new Slider('.js--slider');
            }, 1);

            return gsap.to(body, {
              opacity: 1,
              duration: 1,
              delay: 0.25,
            });
          },

          beforeLeave(data) {
            return gsap.to(data.current.container, {
              opacity: 0,
              duration: 0.5,
              delay: 0.4,
            });
          },

          afterLeave(data) {
            if (cursor) {
              cursor.classList.remove('cursor-active');
            }

            if (data.trigger !== 'back') {
              window.scrollTo({
                top: 0,
                left: 0,
              });
            }

            // update body classes
            body.classList.remove('bg-' + data.current.container.dataset.bgColor);
            body.classList.remove('background-' + data.current.container.dataset.bgColor);
            body.classList.remove('text-' + data.current.container.dataset.textColor);
            body.classList.add('bg-' + data.next.container.dataset.bgColor);
            body.classList.add('background-' + data.next.container.dataset.bgColor);
            body.classList.add('text-' + data.next.container.dataset.textColor);

            // // update navigation classes
            // navigation.classList.remove('bg-' + data.current.container.dataset.bgColor);
            // navigation.classList.remove('text-' + data.current.container.dataset.textColor);
            // navigation.classList.add('bg-' + data.next.container.dataset.bgColor);
            // navigation.classList.add('text-' + data.next.container.dataset.textColor);

            // navigationWrapper.classList.remove('text-' + data.current.container.dataset.textColor);
            // navigationWrapper.classList.add('text-' + data.next.container.dataset.textColor);

            // navigationLinks.forEach(element => {
            //   element.classList.remove('hover:text-' + data.current.container.dataset.textHoverColor);
            //   element.classList.add('hover:text-' + data.next.container.dataset.textHoverColor);
            // });
          },

          beforeEnter(data) {
            html.lang = data.next.container.dataset.langcode ? data.next.container.dataset.langcode : 'de';
            if (languageSwitcher) {
              languageSwitcher.href = data.next.container.dataset.translationUrl;
            }

            data.next.container.style.opacity = 0;
            if (cursor) {
              cursor.classList.remove('!bg-white');
            }
          },

          enter(data) {
            data.current.container.remove();

            // // language is switched, current and next container have same pageid
            // if (data.current.container.dataset.pageid == data.next.container.dataset.pageid) {
            //   scrollX = barba.history.current.scroll.x;
            //   scrollY = barba.history.current.scroll.y;
            //   window.scrollTo(scrollX, scrollY);
            // }

            // reinit JS
            setTimeout(() => {
              new Scrolling();
              new Scrollup();
              new NavMobile();
              new FooterButton();
              new Title();
              new Projects();
              new ServicesMenu();
              new StickyHeadline();
              new Video('.js--video');
              new Cursor();
              new Slider('.js--slider');
            }, 1);
          },

          afterEnter(data) {
            return gsap.to(data.next.container, {
              opacity: 1,
              duration: 1,
              delay: 1,
            });
          }

        },
      ],

    });
  }

}
